import { Pipe, PipeTransform } from '@angular/core';
/*
 * Converts an overly long filename to [first 10 characters]...[last 10 characters]
 * Usage:
 *   filename | truncateFilename
 * Example:
 *   {{ someoverlylongfilenamethatnoonecaresabout.png | truncateFilename }}
 *   formats to: someoverly...sabout.png
*/
@Pipe({name: 'truncateFilename'})
export class TruncateFilenamePipe implements PipeTransform {
  transform(filename: string): string {
    // Ensure that the passed in filename needs truncation
    if (filename.length <= 23) {
        // This filename does not need to be truncated, because it is not overly long
        return filename;
    } else {
        return filename.substring(0, 10) + '...' + filename.substring(filename.length - 10, filename.length);
    }
  }
}